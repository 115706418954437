export default function formatDate(dateString, endDateString = dateString) {
  const startDate = new Date(dateString);
  const endDate = new Date(endDateString);
  const options = { month: "long" };

  const month = startDate.toLocaleDateString("en-US", options);
  const monthEnd = endDate.toLocaleDateString("en-US", options);

  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const year = startDate.getFullYear();
  const yearEnd = endDate.getFullYear();

  return year != yearEnd
    ? `${month} ${startDay}, ${year} - ${monthEnd} ${endDay}, ${yearEnd}`
    : month != monthEnd
    ? `${month} ${startDay} - ${monthEnd} ${endDay}, ${year}`
    : dateString == endDateString
    ? `${month} ${startDay}, ${year}`
    : `${month} ${startDay} - ${endDay}, ${year}`;
}
