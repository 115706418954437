import React , { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Alert } from "@mui/material";

const ResetPass = () => {
    const navigate = useHistory();
    const [msg, setMsg] = useState();

    const [email, setEmail] = useState("");
    const [checkemail, setcheckemail] = useState(false);

    const handleSubmit = async (event) => {
      event.preventDefault();
      localStorage.setItem("emailpass", email);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
          { email},
          { headers: { "Content-Type": "application/json" } }
        );
        // alert("We have sent you a password reset instruction. Please check your email.");

        navigate.push("/set-pass");
        // setcheckemail(true);
        console.log(response.data);
  
        return response.data;
      } catch (error) {
        if (error.response) {
          setMsg(
            error.response.data.message
              ? error.response.data.message
              : error.response.data.errors[0].msg
          );
        } else if (error.request) {
          console.error("Error request data:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
      }
    };
    // const handleSet = () => {
    //     navigate.push("/set-pass");
    //   };
  return (
    <div className="container-fluid p-0 mt-5">
      {checkemail ? 
      <div className="row">
        <div className="row">
          <div className="col w-100 d-flex flex-column justify-content-center">
            <div className="container form-container bg-white d-flex align-items-center justify-content-center p-0 ">
              <div className="w-100 d-flex flex-column align-items-center mb-5">
                <h3 className=" w-100 d-flex justify-content-start mb-5 fw-bold">
                We have sent you a password reset instruction. Please check your email.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div className="row">
      <div className="col w-100 d-flex flex-column justify-content-center">
        <div className="container form-container bg-white d-flex align-items-center justify-content-center p-0 ">
          <form onSubmit={handleSubmit} className="w-100 d-flex flex-column align-items-center mb-5">
            <h1 className="login-heading w-100 d-flex justify-content-start mb-5 fw-bold">
              Forgot your password?
            </h1>
            <label htmlFor="email" className="w-100 mb-3">
              E-mail <span className="required">*</span>
              <input
                type="email"
                className="form-control"
                id="email"
                required
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            {msg && (
              <Alert severity="error" className="mt-3 w-100">
                {msg}
              </Alert>
            )}

            <button
              type="submit"
              className="login-button w-100 mt-5 p-2 fw-bold"
              // onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
      }

    </div>
  );
};

export default ResetPass;
