import { useEffect, useState } from "react";
import "./profile.css";
import ChangePass from "./ChangePass/ChangePass";
import Information from "./Information";
import { GetUser } from "../../utiles/GetUserInfo";
import getCookie from "../../utiles/Cookie";
export default function Profile() {
  const user = Boolean(getCookie("user-login-fps"))
  ? JSON.parse(getCookie("user-login-fps"))
  : null;
  const [open, setOpen] = useState(false);
  const [edit_check, setEdit_check] = useState(false);  
  const [data, setDate] = useState();
  useEffect(() => {
    GetUser(user).then((res) => {
      setDate(res);
    });
  }, [user===null]);
  return (
    <>
      <div  className="d-flex profile">
        <Information user={data} setOpen={setOpen} setEdit_check={setEdit_check} edit_check={edit_check}/>
      </div>
      <ChangePass open={open} setOpen={setOpen} />
    </>
  );
}
