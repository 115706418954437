import React , {useEffect} from "react";
// import { useLocation } from "react-router-dom";
import Hero from "./hero/Hero"
import About from './about/About'
import Gallary from './gallary/gallary'
import PreviousEvents from "./PreviousEvents/PreviousEvents";
import Hackathon from "./Hackathon/Hackathon";


// import News from './news/News'
// import Sponsor from './sponsors/Sponsors'





const Home = () => {
  // const about = window.location.pathname;  
  // console.log(about, "about");
  // useEffect(() => {
  //   if (about) {
  //     const flag = document.getElementById(about);
  //     if (flag) {
  //       flag.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }
  // });

  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
      <Hero />
      <About/>
      {/* <Hackathon/> */}
      <Gallary/>
      {/* <PreviousEvents/> */}
    </>
  )
}

export default Home
