import Joi from "joi";

// Define the schema for file validation
const fileValidation = Joi.any()
  .custom((value, helpers) => {
    if (
      value &&
      value.type &&
      ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
    ) {
      return value;
    }
    return helpers.message("Only JPEG, PNG, or PDF files are allowed.");
  })
  .required()
  .messages({
    "any.required": "A file is required.",
  });

// Define the valid regex for registration number
const validRegex = /^[A-Za-z0-9]+$/;

// Define the schema
const schema = Joi.object({
  //  job: Joi.string().required(),
  // student_id_numbrer: Joi.when("job", {
  //   is: "Student",
  //   then: Joi.string().min(15).required(),
  //   otherwise: Joi.optional(),
  // }),
  student_id_numbrer: Joi.when("job", {
    is: Joi.string().valid("Student"),
    then: Joi.number().min(99999999999999).messages({
      "number.min": "Student Id Number must be at least 15 number long",
    }),
    otherwise: Joi.optional(),
  }),
  // student_id_file: Joi.when("job", {
  //   is: "Student",
  //   then: fileValidation,
  //   otherwise: Joi.optional(),
  // }),
  registration_number: Joi.when("job", {
    is: Joi.string().valid("Professional Practitioner", "Health Practitioner"),
    then: Joi.string()
      .min(5)
      .pattern(validRegex) 
      .messages({
        "string.min":
          "Registration number length must be at least 5 characters long",
        "string.pattern.base":
          "Registration number must contain only English letters and numbers.",
      }),
    otherwise: Joi.optional(),
  }),
  // professional_classification: Joi.when("job", {
  //   is: "Professional Practitioner",
  //   then: Joi.string()
  //     .pattern(/^[a-zA-Z\s]+$/)
  //     .required()
  // .messages({
  //   "string.pattern.base":
  //     "Only letters are allowed for Professional Classification.",
  // }),
  //   otherwise: Joi.optional(),
  // }),
  professional_classification: Joi.when("job", {
    is: Joi.string().valid("Professional Practitioner"),
    then: Joi.string()
      .pattern(/^[a-zA-Z\s]+$/)
      .messages({
        "string.pattern.base":
          "Only letters are allowed for Professional Classification.",
      }),
    otherwise: Joi.optional(),
  }),
  health_practitioner_type: Joi.when("job", {
    is: "Health Practitioner",
    then: Joi.string().required(),
    otherwise: Joi.optional(),
  }),
  other: Joi.when("job", {
    is: "Health Practitioner",
    then: Joi.when("health_practitioner_type", {
      is: "Others",
      then: Joi.string().required(),
      otherwise: Joi.optional(),
    }),
    otherwise: Joi.optional(),
  }),
});

export default schema;
