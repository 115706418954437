import { useParams } from "react-router-dom"
import getCookie from "../../utiles/Cookie"

export default function ButtonFile({file}) {
  const tokenFromLocalStorage = getCookie('fps_TokenCode')
  const { id } = useParams()

  const getFile = async (file) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin-dashboard/application/${id}/file?type=${file}`,
        {
          headers: {
            token: `Bearer ${tokenFromLocalStorage}`,
            'Content-Type': 'application/json',
          },
        },
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      window.open(data, '_blank')
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <button
      onClick={() => getFile(file)}
      className='d-flex border p-1 w-100'
      style={{
        borderRadius: '6px',
        justifyContent: 'center',
        maxWidth: '300px',
      }}
    >
      <svg
        width='19'
        height='20'
        viewBox='0 0 19 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.0809 18.5417H6.91921C3.03213 18.5417 1.36963 16.7917 1.36963 12.7001V12.5917C1.36963 8.89173 2.75505 7.1084 5.85838 6.80006C6.17505 6.77506 6.47588 7.02506 6.50755 7.36673C6.53921 7.7084 6.30171 8.01673 5.96921 8.05006C3.48338 8.29173 2.55713 9.52506 2.55713 12.6001V12.7084C2.55713 16.1001 3.69713 17.3001 6.91921 17.3001H12.0809C15.303 17.3001 16.443 16.1001 16.443 12.7084V12.6001C16.443 9.5084 15.5009 8.27506 12.9675 8.05006C12.643 8.01673 12.3975 7.71673 12.4292 7.37506C12.4609 7.0334 12.738 6.77506 13.0705 6.8084C16.2213 7.09173 17.6305 8.8834 17.6305 12.6084V12.7167C17.6305 16.7917 15.968 18.5417 12.0809 18.5417Z'
          fill='#404547'
        />
        <path
          d='M9.5 13.1249C9.17542 13.1249 8.90625 12.8416 8.90625 12.4999V3.0166C8.90625 2.67493 9.17542 2.3916 9.5 2.3916C9.82458 2.3916 10.0938 2.67493 10.0938 3.0166V12.4999C10.0938 12.8416 9.82458 13.1249 9.5 13.1249Z'
          fill='#404547'
        />
        <path
          d='M12.1523 5.50003C12.0019 5.50003 11.8515 5.4417 11.7327 5.3167L9.5002 2.9667L7.2677 5.3167C7.03812 5.55837 6.65812 5.55837 6.42854 5.3167C6.19895 5.07503 6.19895 4.67503 6.42854 4.43337L9.08062 1.6417C9.3102 1.40003 9.6902 1.40003 9.91979 1.6417L12.5719 4.43337C12.8015 4.67503 12.8015 5.07503 12.5719 5.3167C12.461 5.4417 12.3027 5.50003 12.1523 5.50003Z'
          fill='#404547'
        />
      </svg>
      <p className='mx-3 my-0'>File</p>
    </button>
  )
}
