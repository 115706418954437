import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { setCookie } from "../../utiles/Cookie";
import { Alert } from "@mui/material";

const Login = () => {
  const navigate = useHistory();
  const [msg, setMsg] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      const cookieExpirationDays = rememberMe ? 30 : 1;
  
      setCookie("user-login-fps", JSON.stringify(response.data), cookieExpirationDays);

      navigate.push("/");

      return response.data;
    } catch (error) {
      if (error.response) {
        setMsg(
          error.response.data.message
            ? error.response.data.message
            : error.response.data.errors[0].msg
        );
      } else if (error.request) {
        console.error("Error request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const handleForgotPassword = () => {
    navigate.push("/reset-pass");
  };
  const handleSignUp = () => {
    navigate.push("/sign-up");
  };

  return (
    <div className="container-fluid p-0 mb-5">
      <div className="row">
        <div className="col w-100 d-flex flex-column justify-content-center">
          <div className="container form-container bg-white d-flex align-items-center justify-content-center p-0">
            <form
              className="w-100 d-flex flex-column align-items-center mb-5"
              onSubmit={handleSubmit}
            >
              <h1 className="login-heading w-100 d-flex justify-content-start mb-5 fw-bold">
                Login
              </h1>
              <label htmlFor="email" className="w-100 mb-3">
                E-mail <span className="required">*</span>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setMsg(null);
                    setEmail(e.target.value);
                  }}
                />
              </label>
              <label htmlFor="pass" className="w-100 mb-3">
                Password <span className="required">*</span>
                <input
                  type="password"
                  className="form-control"
                  id="pass"
                  value={password}
                  onChange={(e) => {
                    setMsg(null);
                    setPassword(e.target.value);
                  }}
                />
              </label>

              <div className="w-100 mb-3 d-flex align-items-center justify-content-between">
                <label className="checkbox-label d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input me-3"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  Remember Me
                </label>

                <button
                  type="button"
                  className=" btn-link forgot-password-link fw-bolder"
                  onClick={handleForgotPassword}
                >
                  Forgot Password
                </button>
              </div>

              {msg && (
                <Alert severity="error" className="mt-3 w-100">
                  {msg}
                </Alert>
              )}

              <button
                type="submit"
                className="login-button w-100 mt-2 p-2 fw-bold"
              >
                Sign in
              </button>
              <p className="mt-4">
                Don’t have an account?{" "}
                <button
                  type="button"
                  onClick={handleSignUp}
                  className="sign-up"
                >
                  Create Account
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
