import React from "react";
import "./gallary.css";
import G1 from "../../../imgs/ga1.png";
import G2 from "../../../imgs/ga2.png";
import G3 from "../../../imgs/gg3.png";
import { useTranslation } from "react-i18next";

const Gallary = () => {
  const { t } = useTranslation("global");

  return (
    <div className="gallary ">
      <div
        className="container "
        style={{ paddingBottom: "130px", paddingTop: "100px" }}
      >
        <h2>{t("gallary.GALLERY")}</h2>
        <div className="row my-5 py-4">
          <div className="col-md-4">
            <div className="box_img">
              <img src={G1} alt=".." className="w-100" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="box_img">
              <img src={G2} alt=".." className="w-100" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="box_img">
              <img src={G3} alt=".." className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallary;
