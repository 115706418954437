import { useState, useEffect } from "react";
import getCookie from "../../utiles/Cookie"; // Import the getCookie function
import { Alert } from "@mui/material";
export default function Information({
  user,
  setOpen,
  setEdit_check,
  edit_check,
}) {
  const [msg, setMsg] = useState();
  const [updatedUser, setUpdatedUser] = useState(user);
  const usercookie = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  useEffect(() => {
    setUpdatedUser(user);
  }, [user]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUser({ ...updatedUser, [name]: value });
  };

  const updateUserData = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/profile`,
        {
          method: "put",
          headers: {
            token: `Bearer ${usercookie?.token}`,
            "Content-Type": "application/json",
            // },
          },
          body: JSON.stringify({
            first_name: updatedUser.first_name,
            middle_name: updatedUser.middle_name,
            last_name: updatedUser.last_name,
            gender: updatedUser.gender,
            national_id: updatedUser.national_id,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        alert("User data updated successfully");
        window.location.reload();
        // Handle successful update
        console.log("User data updated successfully");
      } else {
        setMsg(
          res.errors
            ? res.errors[0].msg
            : res.message
        );
        // Handle error
      }
    } catch (error) {
    
      !error.response &&
        setMsg(
          "Could you please check your network connection? It seems there may be an issue."
        );
    
      console.error("Error:", error);
    }
  };

  return (
    <div className="container my-5 information-profile">
      <h3 className="mb-4">Welcome, {user?.first_name}</h3>
      <form onSubmit={updateUserData} className="info-card">
        <div className="d-flex justify-content-between">
          <h4>Account Details</h4>
          {edit_check ? (
            <></>
          ) : (
            <button className="edit_btn" onClick={() => setEdit_check(true)}>
              Edit Profile
            </button>
          )}


          {edit_check ? <button className="edit_btn">Update</button> : <></>}

        </div>
        <div className="primary-info row my-3 ">
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">First Name</p>
            {edit_check ? (
              <input
                type="text"
                required
                name="first_name"
                value={updatedUser.first_name}
                onChange={handleInputChange}
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "5px",
                  outline: "none",
                }}
                className="px-2"
              />
            ) : (
              <p className="m-0 p-0 detials">{user?.first_name}</p>
            )}
          </div>
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">Middle Name</p>
            {edit_check ? (
              <input
                type="text"
                required
                name="middle_name"
                value={updatedUser?.middle_name}
                onChange={handleInputChange}
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "5px",
                  outline: "none",
                }}
                className="px-2"
              />
            ) : (
              <p className="m-0 p-0 detials">{user?.middle_name}</p>
            )}
          </div>
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">Last Name</p>
            {edit_check ? (
              <input
                required
                type="text"
                name="last_name"
                value={updatedUser.last_name}
                onChange={handleInputChange}
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "5px",
                  outline: "none",
                }}
                className="px-2"
              />
            ) : (
              <p className="m-0 p-0 detials">{user?.last_name}</p>
            )}
          </div>
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">Gender</p>
            {edit_check ? (
              <select
                name="gender"
                required
                value={updatedUser.gender}
                onChange={handleInputChange}
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "5px",
                  outline: "none",
                  width: "261px",
                  height: "28.6px",
                }}
                className="px-2 "
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            ) : (
              <p className="m-0 p-0 detials">{user?.gender}</p>
            )}
          </div>
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">Email</p>
            <p className="m-0 p-0 detials">{user?.email}</p>
          </div>
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">Mobile Number</p>
            <p className="m-0 p-0 detials">{user?.mobile_number}</p>
          </div>
          <div className="primary-info__details col-12 col-md-4 col-sm-6  my-2  ">
            <p className="m-0 p-0 label">National ID</p>
            {edit_check ? (
              <input
                required
                type="text"
                name="national_id"
                value={updatedUser.national_id}
                onChange={handleInputChange}
                style={{
                  border: "1px solid #dadada",
                  borderRadius: "5px",
                  outline: "none",
                }}
                className="px-2"
              />
            ) : (
              <p className="m-0 p-0 detials">{user?.national_id}</p>
            )}
          </div>
          {msg && (
            <Alert severity="error" className="my-3 w-100">
              {msg}
            </Alert>
          )}
        </div>
      </form>
      <div className="info-card pass">
        <h4>Change Password</h4>
        <p>Enter your old password in order to create a new one.</p>
        <button
          className="border-0 bg-transparent"
          onClick={() => setOpen(true)}
        >
          Change Password
        </button>
      </div>
    </div>
  );
}
