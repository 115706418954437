import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Hero.css";
import axios from "axios";
import Info from "./Info";
import CounterBox from "./CounterBox";
// import phoneBg from "../../../imgs/bg-hero-phone.svg";
import bg from "../../../imgs/medical-health-care-icon-element-interactive-desig-2023-11-27-04-55-09-utc.mp4";

const Hero = () => {
  const [event, setEvent] = useState(null);
  const [state, setState] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchEvent = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL}/event/active`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        setEvent(response.data.result);
        // const { countdown } = response.data.result;
        // setState({
        //   days: countdown.days,
        //   hours: countdown.hours,
        //   minutes: countdown.minutes,
        //   seconds: countdown.seconds,
        // });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchEvent();
  }, []);

  useEffect(() => {
    setNewTime();
    // const interval = setInterval(() => setNewTime(), 1000);
    // return () => clearInterval(interval);
  }, [event]);

  const setNewTime = () => {

    if (event?.date) {
      const countdownDate =new Date(event.date);
      countdownDate.setHours(event.startTime, 0, 0, 0); 

      const currentTime = new Date();
      const distanceToDate = countdownDate - currentTime; 
      console.log(distanceToDate,"sssss")

      if (distanceToDate > 0) {
        let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor(
          (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
        );
        let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

        const addLeadingZero = (value) => value.toString().padStart(2, "0");

        setState({
          days: addLeadingZero(days),
          hours: addLeadingZero(hours),
          minutes: addLeadingZero(minutes),
          seconds: addLeadingZero(seconds),
        });
      } else {
        setState({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      }
    }
  };

  const { t, i18n } = useTranslation("global");
  document.body.dir = i18n.dir();

  return (
    <section
      className="hero p-5 position-relative  d-flex  align-items-center"
      style={{ transform: i18n.language === "ar" ? "scaleX(-1)" : "none" }}
    >
      <video className="video-bg" autoPlay muted loop playsInline>
        <source src={bg} type="video/mp4" />
        {/* <img src={phoneBg} alt="event" className="d-none w-100"></img> */}
      </video>

      <div className="w-100">
        <div className="phoneBg d-none w-100 ">
          <video className="" autoPlay muted loop playsInline>
            <source src={bg} type="video/mp4" />
            {/* <img src={phoneBg} alt="event" className="d-none w-100"></img> */}
          </video>
        </div>

        <div className="container d-flex justify-content-between align-items-end  gap-1 row-gap-5 hero-section">
          <Info event={event} />
          <CounterBox state={state} />
        </div>
      </div>
    </section>
  );
};

export default Hero;
