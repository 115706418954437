import { Skeleton } from "@mui/material";

export default function SkeltonCard(){
    return(
<div className="d-flex w-100 gap-2">
<Skeleton variant="square" width={40} height={'100%'} />
<div className="w-100">
    <Skeleton className="w-100"></Skeleton>
    <Skeleton className="w-100"></Skeleton>
    <Skeleton className="w-100"></Skeleton>

</div>
</div>
    )
}