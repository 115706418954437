import React, { useEffect, useState } from "react";
import JobView from "./JobView";
// import NonJobView from "./NonJobView";
import axios from "axios";
import getCookie from "../../utiles/Cookie.js";

const ViewApplication = (props) => {
  const eventId = props.match.params.id;

  return (
    <>
      <JobView eventId={eventId} />
    </>
  );
};

export default ViewApplication;
