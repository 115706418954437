import { Skeleton } from "@mui/material";
import { useEffect } from "react";

export default function CounterBox({ state }) {
  useEffect(() => {
    console.log(state, "dddddddd");
  });

  return (
    <>
      <div className="counter_box ">
        <div className="counter-container d-flex justify-content-center  gap-3">
          <div className="counter-timer-wrapper " style={{ width: "5.5rem" }}>
            {!state ? (
              <Skeleton style={{ height: "100px", width: "100%" }} />
            ) : (
              <h3 className="counter-timer">
                {state.days > 0 ? state?.days : "00"}
              </h3>
            )}
            <span>DAYS</span>
          </div>

          <div className="counter-timer-wrapper " style={{ width: "5.5rem" }}>
          {!state ? (
              <Skeleton style={{ height: "100px", width: "100%" }} />
            ) : (
              <h3 className="counter-timer">
                {state.hours > 0 ? state?.hours : "00"}
              </h3>
            )}
            <span>HOURS</span>
          </div>

          <div className="counter-timer-wrapper " style={{ width: "5.5rem" }}>
          {!state ? (
              <Skeleton style={{ height: "100px", width: "100%" }} />
            ) : (
              <h3 className="counter-timer">
                {state.minutes > 0 ? state?.minutes : "00"}
              </h3>
            )}
           
            <span>MINUTES</span>
          </div>

          {/* <div className="counter-timer-wrapper" style={{ width: "5.5rem" }}>
            <h3 className="counter-timer">{state?.seconds || "0"}</h3>
            <span>Seconds</span>
          </div> */}
        </div>
        {/* } */}
      </div>
    </>
  );
}
