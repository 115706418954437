import React, { useState, useEffect, useRef } from "react";
import "./ViewApplication.css";
import axios from "axios";
import getCookie from "../../utiles/Cookie";
import ButtonFile from "./ButtonFile";
import { Skeleton, Button, Snackbar, Alert } from "@mui/material";
import { Form } from "react-bootstrap";
import Joi, { object } from "joi";
import schema from "../../validation/even-registeration-validation";
import toTitleCase from "../../utiles/toTitleCase";
export default function JobView({ eventId }) {
  const [data, setData] = useState(null);
  const [id, setID] = useState(null);
  const [acceptance, setAcceptance] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [loadingUpdate, setloadingUpdate] = useState(false);
  const [dataAll, setDataAll] = useState();
  const [UpdateSuccess, setUpdateSuccess] = useState(false);
  const [formData, setFormData] = useState({});
  const [edit, setEdit] = useState(false);
  const [requiredFields, setRequiredFields] = useState(false);
  const [eventFields, setEventFields] = useState([]);
  const [errors, setErrors] = useState({});
  const fieldRefs = useRef([]);
  const fieldRefsCondition = useRef([]);
  const [Inter_board, set_Inter_board] = useState(false);
  const [msgError, setMsgError] = useState();
  const [msgErrorBoard, setMsgErrorBoard] = useState();

  // ======object exam array
  const [rows, setRows] = useState([
    { name: "", status: "", score: 0, attached_file: "" },
  ]);
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;
  // ======fetch data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/application/event/${eventId}`,
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${user?.token}`,
          },
        }
      );
      const rawData = response.data.result.applicationData;
      const filteredSortedData = Object.entries(rawData)
        .filter(
          ([key]) =>
            !key?.includes("board_acceptance_path") && !key?.includes("sml")
        )
        .sort(([keyA], [keyB]) => {
          if (keyA?.includes("Exams")) return -1;
          if (keyB?.includes("Exams")) return 1;
          if (keyA?.includes("board")) return 1;
          if (keyB?.includes("board")) return -1;
          return 0;
        });
      setexamarr(
        response.data.result.applicationData?.international_exams?.length > 0 &&
          response.data.result.applicationData?.international_exams[0].name
          ? true
          : false
      );
      setDataAll(response.data.result.applicationData);
      setRows(response.data.result.applicationData.international_exams);
      setID(response.data.result._id);
      // Create a map of field types from eventFields
      const fieldTypesMap = eventFields.reduce((acc, field) => {
        acc[field.field] = field.type;
        return acc;
      }, {});
      const transformedData = filteredSortedData?.map(([key, value]) => {
        // Check the field type in the map
        const fieldType = fieldTypesMap[key];
        // Convert value based on its type
        if (fieldType === "number") {
          value = Number(value);
        } else if (fieldType === "string") {
          value = String(value);
        }
        return [key, value];
      });

      const transformedResult = transformData(transformedData, eventFields);
      setData(transformedResult);
      setAcceptance(rawData.international_board_acceptance ? "yes" : "no");
    } catch (e) {
      console.error("Error fetching data", e);
      setEdit(false);
    }
  };
  function transformData(transformedData, eventFields) {
    return transformedData?.map(([fieldName, value]) => {
      const fieldInfo = eventFields.filter(
        (eventField) => eventField.field === fieldName
      );

      const labelTypeObject = fieldInfo
        ? {
            label: fieldInfo[0]?.label,
            type: fieldInfo[0]?.type,
            condition: fieldInfo?.map((i) => {
              return {
                field: i.condition?.field,
                value: i.condition?.value,
              };
            }),
          }
        : {};

      return [fieldName, value, labelTypeObject];
    });
  }

  useEffect(() => {
    fetchData();
  }, [data == null, eventFields]);
  //== fetch personal information
  useEffect(() => {
    const fetchUserData = async () => {
      if (user.token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/profile`,
            {
              headers: {
                "Content-Type": "application/json",
                token: `Bearer ${user.token}`,
              },
            }
          );
          const data = response.data;
          setUserData(data);
        } catch (e) {
          console.error("Error fetching user data", e);
        }
      }
    };
    fetchUserData();
  }, []);
  const getOrdinal = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const mod100 = number % 100;
    const mod10 = number % 10;
    const suffix =
      mod10 > 3 || (mod100 >= 11 && mod100 <= 13)
        ? suffixes[0]
        : suffixes[mod10];

    return `${number}${suffix}`;
  };
  //== render dynamic professional information
  const renderField = (field, value, object) => {
    if (field === "international_board_acceptance") {
      return (
        <div
          style={{ opacity: edit ? 0.4 : 1 }}
          className="primary-info__details col-12 my-2 mb-5"
        >
          <label className="form-label" htmlFor="international-acceptance">
            International board Acceptance
            <p className="label-text">
              Have you been accepted in any international board?
            </p>
          </label>

          <div className="d-flex align-items-center gap-4">
            <label className="custom-radio">
              <input
                disabled={edit}
                type="radio"
                value="yes"
                checked={acceptance === "yes"}
                onChange={(e) => {
                  setAcceptance(e.target.value);
                }}
              />
              <span>Yes</span>
            </label>
            <label className="custom-radio">
              <input
                type="radio"
                disabled={edit}
                value="no"
                checked={acceptance === "no"}
                onChange={(e) => setAcceptance(e.target.value)}
              />
              <span>No</span>
            </label>
          </div>

          {acceptance === "yes" && (
            <>
              <div className="file-container d-flex align-items-end gap-3">
                <div className="row primary-info__details w-100 ">
                  <label
                    htmlFor="formFile"
                    className="form-label primary-info__details"
                  >
                    International board Acceptance file
                    <p className="label-text">Attach file</p>
                  </label>
                  <input
                    type="file"
                    disabled={edit}
                    className="form-control"
                    id="formFile"
                    accept=".pdf, image/*"
                    onChange={(e) => {
                      setMsgErrorBoard(null);
                      const file = e.target.files[0];
                      const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
                      const allowedTypes = [
                        "application/pdf",
                        "image/jpeg",
                        "image/png",
                        "image/svg+xml",
                        "image/webp",
                      ];

                      if (file) {
                        if (!allowedTypes.includes(file.type)) {
                          setError(true);
                          setMsgError(
                            "Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed."
                          );
                          setMsgErrorBoard(
                            "Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed."
                          );
                          e.target.value = null; // Reset the file input
                          return;
                        }

                        if (file.size > maxSizeInBytes) {
                          setError(true);

                          setMsgErrorBoard("Max size 1 MB.");
                          e.target.value = null;
                          return;
                        }
                        setSelectedFile(e.target.files[0]);
                      } else {
                        setSelectedFile(null);
                      }
                    }}
                  />
                </div>
                <Button
                  className="update"
                  type="submit"
                  onClick={() => handleUpdateClick()}
                  disabled={loading || edit}
                  style={{
                    backgroundColor: loading ? "gray" : "#1eb4bc",
                    border: "0",
                    color: "white",
                  }}
                >
                  UPDATE
                </Button>
              </div>
              <>
                {msgErrorBoard && !edit && (
                  <Alert severity="error" className="my-3">
                    {msgErrorBoard}
                  </Alert>
                )}
              </>
            </>
          )}
        </div>
      );
    } else if (field == "international_exams") {
      return (
        <div className="primary-info__details col-12  my-2 mb-5">
          <label className="form-label" htmlFor="international-exams">
            International Exams
            <p className="label-text">Do you have any international exams?</p>
          </label>
          <div id="international-exams">{examarr ? "Yes" : "No"}</div>
          {examarr &&
            value?.map((exam, index) => (
              <div
                className="row pt-3 px-3 mb-2"
                key={index}
                style={{ border: "1px solid gray", borderRadius: "15px" }}
              >
                <p className="primary-info__heading fw-bolder">
                  {`${getOrdinal(index + 1)} exam`}
                </p>
                <div className="col-sm-6 col-12 col-md-2 my-2 mb-5">
                  <label className="form-label">Name</label>
                  <div>{exam?.name || "N/A"}</div>
                </div>
                <div className="col-sm-6 col-12 col-md-2 my-2 mb-5">
                  <label className="form-label">Score</label>
                  <div>{exam?.score || "N/A"}</div>
                </div>
                <div className="col-sm-6 col-12 col-md-2 my-2 mb-5">
                  <label className="form-label">Pass/Fail</label>
                  <div>{exam?.status || "N/A"}</div>
                </div>
                <div className="col-sm-6 col-12 col-md-2 my-2 mb-5">
                  <label className="form-label">Attach file</label>
                  {/* <div>{exam?.attached_file || "N/A"}</div> */}
                  <div>
                    {data?.map(([key, value, object]) =>
                      key.includes(index) &&
                      key.includes("attached_file") &&
                      key.includes("international_exams") ? (
                        <div key={key}>
                          <ButtonFile />
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      );
    } else if (object.type == "file") {
      return (
        <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
          <label class="form-label" htmlFor="firstName">
            {object.label}
          </label>{" "}
          <div>{value == null ? <p>No</p> : <ButtonFile />}</div>
        </div>
      );
    } else {
      if (!field.includes("exam")) {
        return (
          <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
            <label class="form-label" htmlFor="firstName">
              {object.label}
            </label>{" "}
            <div>{value == null ? <p>No</p> : value}</div>
          </div>
        );
      }
    }
  };
  //== render dynamic form
  const renderFieldInputs = (fieldConfig, index) => {
    const {
      field,
      required,
      type,
      enum_values,
      condition,
      label,
      placeholder,
    } = fieldConfig;

    const additionalProps = {};

    if (condition) {
      additionalProps["data-condition-field"] = condition?.field;
      additionalProps["data-condition-value"] = condition?.value;
    }

    const renderLabel = label || toTitleCase(field.replace(/_/g, " "));
    const renderPlaceholder =
      placeholder || `Enter ${field.replace(/_/g, " ")}`;
    var result = data?.filter(([key]) => key === field);
    switch (type) {
      case "enum":
        return (
          <Form.Group
            controlId={field}
            key={field}
            className="col-12 col-sm-4 mb-5"
          >
            <Form.Label>
              {renderLabel}
              {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Form.Control
              as="select"
              name={field}
              ref={
                !condition.field
                  ? (el) => (fieldRefsCondition.current[index] = el)
                  : (el) => (fieldRefs.current[index] = el)
              }
              data-accepted={field}
              defaultValue={
                Array.isArray(result) && result.length > 0 ? result[0][1] : ""
              }
              onChange={handleChange}
              required={required}
              {...additionalProps}
            >
              <option value="">{renderPlaceholder}</option>
              {enum_values?.map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Control>
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );
      case "file":
        return (
          <Form.Group
            controlId={field}
            className="col-12 col-sm-4 mb-5"
            key={field}
          >
            <Form.Label>
              {renderLabel}
              {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Form.Control
              data-accepted={field}
              type="file"
              ref={
                !condition?.field
                  ? (el) => (fieldRefsCondition.current[index] = el)
                  : (el) => (fieldRefs.current[index] = el)
              }
              name={field}
              onChange={handleChange}
              required={required}
              {...additionalProps}
            />
            <p>
              {Array.isArray(result) && result.length > 0
                ? result[0][1].slice(0, 20) + "..."
                : ""}
            </p>
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );
      case "number":
        return (
          <Form.Group
            controlId={field}
            key={field}
            className="col-12 col-sm-4 mb-5"
          >
            <Form.Label>
              {renderLabel}{" "}
              {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Form.Control
              data-accepted={field}
              type="number"
              name={field}
              ref={
                !condition?.field
                  ? (el) => (fieldRefsCondition.current[index] = el)
                  : (el) => (fieldRefs.current[index] = el)
              }
              defaultValue={
                Array.isArray(result) && result.length > 0 ? result[0][1] : ""
              }
              onChange={handleChange}
              placeholder={renderPlaceholder}
              required={required}
              {...additionalProps}
            />
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );
      case "string":
        return (
          <Form.Group
            controlId={field}
            key={field}
            className="col-12 col-sm-4 mb-5"
          >
            <Form.Label>
              {renderLabel}{" "}
              {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Form.Control
              type="text"
              data-accepted={field}
              name={field}
              ref={
                !condition?.field
                  ? (el) => (fieldRefsCondition.current[index] = el)
                  : (el) => (fieldRefs.current[index] = el)
              }
              defaultValue={
                Array.isArray(result) && result.length > 0 ? result[0][1] : ""
              }
              onChange={handleChange}
              placeholder={renderPlaceholder}
              required={required}
              {...additionalProps}
            />
            {errors[field] && <p className="text-danger">{errors[field]}</p>}
          </Form.Group>
        );

      // Case for Array Input Type (International Board Acceptance)
      case "array":
        return (
          <>
            <div className="col-12">
              <div className="mb-4">
                <label className="form-label lable_text d-block">
                  International Exams
                </label>
                <span className="d-block">
                  Do you have any international exams?
                </span>
                <div className="d-flex align-items-center gap-4">
                  <label className="custom-radio" style={{ padding: "1px 0" }}>
                    <input
                      defaultChecked={rows?.length > 0 && rows[0]?.name}
                      onChange={(e) => {
                        set_Inter_board(e.target.value);
                        setexamarr(true);
                        setRows(
                          dataAll.international_exams?.length > 0 &&
                            dataAll.international_exams[0].name
                            ? dataAll.international_exams
                            : [
                                {
                                  name: "",
                                  status: "",
                                  score: 0,
                                  attached_file: "",
                                },
                              ]
                        );
                      }}
                      className="form-check-input mx-2 "
                      name="checkinternational_board"
                      type="radio"
                      value="true"
                      id="yes_board"
                    />
                    <span>Yes</span>
                  </label>
                  <label className="custom-radio" style={{ padding: "1px 0" }}>
                    <input
                      defaultChecked={!rows.length > 0 || !rows[0]?.name}
                      onChange={(e) => {
                        set_Inter_board(e.target.value);
                        setexamarr(false);
                      }}
                      className="form-check-input mx-2"
                      name="checkinternational_board"
                      type="radio"
                      id="No_board"
                      value="false"
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Conditionally Rendered Rows */}
            <div className="col-md-12">
              {examarr ? (
                <>
                  {rows.map((row, index) => (
                    <div className="row" key={index}>
                      <div className="col-md-2">
                        <div className="mb-4">
                          <label
                            htmlFor="exam_name"
                            className="form-label label_text"
                          >
                            Name
                          </label>
                          <Form.Control
                            as={"input"}
                            type="text"
                            value={row?.name}
                            required
                            onChange={(e) => {
                              handleChangeExam(index, "name", e.target.value);
                            }}
                            className={`form-control input_text ${
                              !row?.name && requiredFields
                                ? "border-danger border-2"
                                : ""
                            }`}
                            id="exam_name"
                            placeholder="Enter exam name"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="mb-4">
                          <label
                            htmlFor="exam_score"
                            className="form-label label_text"
                          >
                            Score
                          </label>
                          <Form.Control
                            as={"input"}
                            required
                            value={row?.score}
                            type="number"
                            onChange={(e) =>
                              handleChangeExam(index, "score", e.target.value)
                            }
                            className={`form-control input_text ${
                              !row?.score && requiredFields
                                ? "border-danger"
                                : ""
                            }`}
                            id="exam_score"
                            placeholder="Enter exam score"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="mb-4">
                          <label className="form-label label_text">
                            Pass/Fail
                          </label>
                          <Form.Control
                            as={"select"}
                            required
                            value={row?.status}
                            className={`form-select input_text ${
                              (row?.status == "" || !row?.status) &&
                              requiredFields
                                ? "border-danger"
                                : ""
                            }`}
                            onChange={(e) =>
                              handleChangeExam(index, "status", e.target.value)
                            }
                          >
                            <option value="">Select Pass/Fail</option>
                            <option value="pass">Pass</option>
                            <option value="fail">Fail</option>
                          </Form.Control>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-4">
                          <label className="form-label label_text">
                            Attach File
                          </label>
                          <label
                            role="button"
                            htmlFor={`examFile${index}`}
                            className={`align-items-center gap-2 d-flex border rounded ${
                              !row?.attached_file?.name &&
                              requiredFields &&
                              !data?.some(
                                ([key, value, object]) =>
                                  key.includes(index) &&
                                  key.includes("exam") &&
                                  key.includes("attached_file")
                              )
                                ? "border-danger"
                                : ""
                            }`}
                          >
                            <p
                              className="border h-100 m-0 py-1 px-3"
                              style={{ background: "#f8f9fa" }}
                            >
                              Select file
                            </p>
                            <label htmlFor={`examFile${index}`}>
                              {row?.attached_file?.name || "Select file"}
                            </label>
                          </label>
                          <input
                            hidden
                            id={`examFile${index}`}
                            type="file"
                            accept=".pdf, image/*"
                            onChange={(e) => {
                              const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
                              const file = e.target.files[0];
                              const allowedTypes = [
                                "application/pdf",
                                "image/jpeg",
                                "image/png",
                                "image/svg+xml",
                                "image/webp",
                              ];
                              if (!allowedTypes.includes(file.type)) {
                                setError(true);

                                setMsgError(
                                  "Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed."
                                );
                                e.target.value = null;
                                return;
                              }
                              if (e.target.files[0]?.size <= maxSizeInBytes) {
                                handleChangeExam(
                                  index,
                                  "attached_file",
                                  e.target.files[0]
                                );
                              } else if (
                                e.target.files[0]?.size > maxSizeInBytes
                              ) {
                                setError(true);
                                setMsgError("Max size 1 MB.");
                                e.target.value = null;
                              } else {
                                e.target.value = null;
                              }
                            }}
                            className="form-control input_text custom-file-button"
                          />
                          <div>
                            {data?.map(([key, value, object]) =>
                              key.includes(index) &&
                              key.includes("exam") &&
                              key.includes("attached_file") ? (
                                <div key={key}>
                                  <p>{value.slice(0, 20)}...</p>
                                </div>
                              ) : null
                            )}
                          </div>
                        </div>
                      </div>
                      {index !== 0 && (
                        <div className="col-md-1 d-flex align-items-center">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveRow(index)}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  ))}

                  <div className="btn_Add_div text-end">
                    <button
                      type="button"
                      className="btn_add rounded px-3"
                      onClick={handleAddRow}
                    >
                      +
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </>
        );
      default:
        return null;
    }
  };
  // Function to remove a row by index
  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };
  //== submit form
  const handleUpdate = async (e) => {
    e.preventDefault();
    const arr = [...getAcceptedValues(), ...getAcceptedValuesCondtion()];
    var formDataToSendTest = {};

    for (const key in formData) {
      if (formData.hasOwnProperty(key) && arr.includes(key)) {
        formDataToSendTest[key] = formData[key];
      }
    }

    console.log("formData", formDataToSendTest);
    const filteredFormData = Object.keys(formDataToSendTest).reduce(
      (acc, key) => {
        if (schema.$_terms.keys.find((field) => field.key === key)) {
          acc[key] = formDataToSendTest[key];
        }
        return acc;
      },
      {}
    );
    const { error } = schema.validate(filteredFormData, { abortEarly: false });
    if (error) {
      const validationErrors = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      setloadingUpdate(false);
      return;
    }

    // Function to check required fields based on conditions
    const getRequiredFields = (fields, formData) => {
      return fields.filter((field) => {
        const conditionField = field?.condition?.field?.toLowerCase();
        const conditionValue = field?.condition?.value?.toLowerCase();
        const isConditionMet =
          !conditionField ||
          formData[conditionField]?.toLowerCase() === conditionValue;
        return (
          field.required &&
          isConditionMet &&
          !field.field.toLowerCase().includes("sml") &&
          !field.field.toLowerCase().includes("board")
        );
      });
    };

    // Filter required fields based on the form data and conditions
    const requiredFields = getRequiredFields(eventFields, formData);
    console.log("data", formData);
    const emptyFields = requiredFields.filter(
      (field) => !formData[field.field]
    );
    var dataTestType = eventFields.filter((f) => formDataToSendTest[f.field]);
    for (const d of dataTestType) {
      if (d.type === "file") {
        const file = formDataToSendTest[d.field];
        if (file instanceof File) {
          const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
          const allowedTypes = [
            "application/pdf",
            "image/jpeg",
            "image/png",
            "image/svg+xml",
            "image/webp",
          ];
          if (!allowedTypes.includes(file.type)) {
            setError(true);
            setMsgError("Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed.");
            return; 
          }
              if (file.size > maxSizeInBytes) {
            setError(true);
            setMsgError("Max size 1 MB.");
            return; 
          }
        }
      }
    }
    
    
    
    if (
      emptyFields.length > 0 ||
      (examarr &&
        rows?.filter(
          (row, index) =>
            !row?.name ||
            !row?.score ||
            !row?.status ||
            !(
              row?.attached_file?.name ||
              data?.some(
                ([key, value, object]) =>
                  key.includes(index) && key.includes("exam")
              )
            )
        ).length > 0)
    ) {
      setRequiredFields(true);
      setError(true);

      setMsgError(
        "Please ensure all required fields are filled in. Fields outlined in red are mandatory."
      );
      return;
    }
    setRequiredFields(false);
    setloadingUpdate(true);

    const formDataToSend = new FormData();

    // Append international_exams if rows exist
    if (dataAll.international_exams) {
      if (rows.length > 0 && examarr) {
        rows.forEach((exam, index) => {
          formDataToSend.append(
            `international_exams[${index}][name]`,
            exam?.name || ""
          );
          formDataToSend.append(
            `international_exams[${index}][status]`,
            exam?.status || ""
          );
          formDataToSend.append(
            `international_exams[${index}][score]`,
            exam?.score || ""
          );
          if (exam.attached_file && exam.attached_file instanceof File) {
            formDataToSend.append(
              `international_exams[${index}][attached_file]`,
              exam.attached_file
            );
          }
        });
      } else if (!examarr) {
        // Append as an empty array by adding a key with no value
        formDataToSend.append("international_exams[]", "");
      }
    }

    for (const key in formData) {
      if (formData.hasOwnProperty(key) && arr.includes(key)) {
        formDataToSend.append(key, formData[key]);
      }
    }
    // var object = {};
    // formDataToSend.forEach((value, key) => (object[key] = value));
    // var json = JSON.stringify({ applicationData: object });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/application/update-all-fields/${id}`,
        {
          method: "PUT",
          redirect: "follow",
          body: formDataToSend,
          headers: {
            token: `Bearer ${user.token}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      const result = { ...data, status: response.status, ok: response.ok };
      if (result.ok) {
        setUpdate(true);
        fetchData();
      } else {
        console.error(`Update failed with status ${result.status}:`, result);
        setError(true);
        setMsgError(
          result.message ||
            result.errors[0]?.msg ||
            result.error?.message ||
            result.error ||
            "An error occurred."
        );
      }
    } catch (err) {
      console.error("Network or server error:", err);
      setError(true);

      setMsgError(
        "An unexpected error occurred. Please check your connection and try again."
      );
    } finally {
      setloadingUpdate(false);
      setEdit(false);
    }
  };

  const handleChange = (e) => {
    setMsgError(null);
    const { name, value, type, files } = e.target;
    if (e.target.style && e.target.required) {
      if (value == null || value === "" || value === undefined) {
        e.target.style.border = "2px solid red";
      } else {
        e.target.style.border = "none";
      }
    }

    if (type === "file") {
      const file = files[0];
      const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
      const allowedTypes = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/webp",
      ];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
      if (file) {
      
        if (!allowedTypes.includes(file.type)) {
          setError(true);
        
          setMsgError(
            "Only PDF and image files (JPEG, PNG, SVG, WebP) are allowed."
          );
          // e.target.value = null;
          return;
        }
        if (file.size > maxSizeInBytes) {
          setError(true);
         
          setMsgError("Max size 1 MB.");
          // e.target.value = null;
          return;
        }

        if (schema.$_terms.keys.find((key) => key.key === name)) {
          const fieldSchema = Joi.object({ [name]: schema.extract(name) });
          const { error } = fieldSchema.validate({ [name]: file });
          setErrors({
            ...errors,
            [name]: error ? error.details[0].message : "",
          });
        } else {
          const updatedErrors = { ...errors };
          delete updatedErrors[name];
          setErrors(updatedErrors);
        }
      } else {
        setSelectedFile(null);
      }
    } else if (type === "number") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: +value,
      }));

      if (schema.$_terms.keys.find((key) => key.key === name)) {
        const fieldSchema = Joi.object({ [name]: schema.extract(name) });
        const { error } = fieldSchema.validate({ [name]: value });
        setErrors({ ...errors, [name]: error ? error.details[0].message : "" });
      } else {
        const updatedErrors = { ...errors };
        delete updatedErrors[name];
        setErrors(updatedErrors);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (schema.$_terms.keys.find((key) => key.key === name)) {
        const fieldSchema = Joi.object({ [name]: schema.extract(name) });
        const { error } = fieldSchema.validate({ [name]: value });
        setErrors({ ...errors, [name]: error ? error.details[0].message : "" });
      } else {
        const updatedErrors = { ...errors };
        delete updatedErrors[name];
        setErrors(updatedErrors);
      }
    }
  };
  //== update board acceptance file
  const handleUpdateClick = async () => {
    const formData = new FormData();
    formData.append("board_acceptance_file", selectedFile);
    setLoading(true);
    if (selectedFile == null) {
      setLoading(false);

      setMsgErrorBoard("International Board Acceptance is required.");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/application/board-acceptance/${id}`,
        {
          method: "POST",
          redirect: "follow",
          body: formData,
          headers: {
            token: `Bearer ${user?.token}`,
          },
        }
      );
      const responseData = await response.json();
      setLoading(false);
      if (response) setLoading(false);
      if (!response.ok) {
        console.log(
          `Error: ${
            responseData.errors ||
            JSON.stringify(responseData.error) ||
            response.status
          }`
        );
      } else {
        setUpdateSuccess(true);
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  //== add row for international exam
  const handleAddRow = () => {
    setRows([...rows, { name: "", status: "", score: 0, attached_file: "" }]); // Add a new empty row to the array
  };
  //== change fields of  international exam
  const handleChangeExam = (index, field, value) => {
    setMsgError(null);
    if (field === "score") {
      value = Number(value);
    }
    console.log(rows);

    const updatedRows = [...rows];
    console.log(updatedRows);
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };
  //== fields that updated
  const getAcceptedValues = () => {
    var arr = [];
    fieldRefs?.current?.map((ref, index) => {
      arr.push(ref?.dataset?.accepted);
    });
    return arr;
  };
  const getAcceptedValuesCondtion = () => {
    var arr = fieldRefsCondition?.current?.map((ref) => ref?.dataset?.accepted);
    return arr;
  };
  const [examarr, setexamarr] = useState(false);
  //== fetch requiredfields for event
  const fetchEvent = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        ` ${process.env.REACT_APP_API_URL}/event/${eventId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setEventFields(response.data.result.requiredFields);
    } catch (e) {
      console.error("Error fetching event data", e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEvent();
  }, [eventFields == null, data == null]);
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  return (
    <>
      <Snackbar
        open={UpdateSuccess}
        autoHideDuration={6000}
        onClose={() => setUpdateSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setUpdateSuccess(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          International board Acceptance updated successfully.{" "}
        </Alert>
      </Snackbar>

      <Snackbar
        open={update}
        autoHideDuration={6000}
        onClose={() => setUpdate(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setUpdate(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          your information has been updated successfully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setError(false)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msgError}
        </Alert>
      </Snackbar>
      <div className="container">
        <h1 className="heading-text text-center mt-5 mb-0">Application</h1>
        <div className="primary-info row my-3 mx-0 mx-lg-5">
          <p className="primary-info__heading fs-4 fw-bolder col-12">
            Personal Information
          </p>
          <div className="row" style={{ opacity: edit ? 0.4 : 1 }}>
            <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="firstName">
                First Name
              </label>
              <div id="firstName">{userData.user?.first_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="middleName">
                Middle Name
              </label>
              <div id="middleName">{userData.user?.middle_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="lastName">
                Last Name
              </label>
              <div id="lastName">{userData.user?.last_name || "N/A"}</div>
            </div>
            <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="gender">
                Gender
              </label>
              <div id="gender">{userData.user?.gender || "N/A"}</div>
            </div>
            <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5  ">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <div id="email">{userData.user?.email || "N/A"}</div>
            </div>
            <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="mobileNumber">
                Mobile Number
              </label>
              <div id="mobileNumber">
                {userData.user?.mobile_number || "N/A"}
              </div>
            </div>
            <div className="primary-info__details col-12 col-sm-6 col-md-4  my-2 mb-5 ">
              <label className="form-label" htmlFor="nationalId">
                National Id
              </label>
              <div id="nationalId">{userData.user?.national_id || "N/A"}</div>
            </div>
          </div>
          <div className="primary-info row my-3 mx-0 mx-lg-5"></div>
          {data ? (
            <div className="d-flex justify-content-between gap-3 mb-3 flex-wrap">
              <p class="primary-info__heading fs-4 fw-bolder p-0 m-0">
                Professional Information
              </p>
              <Button
                className={"update"}
                onClick={() => {
                  data?.forEach((e) => {
                    handleChange({ target: { name: e[0], value: e[1] } });
                  });
                  setMsgError(null);
                  setMsgErrorBoard(null);
                  setEdit(!edit);
                }}
                style={{
                  color: "white",
                  backgroundColor: edit ? "red" : loading ? "gray" : "#1eb4bc",
                  border: "0",
                }}
              >
                {edit ? "Cancel" : "Edit"}
              </Button>
              {edit && (
                <>
                  <Form className="col-12" onSubmit={handleUpdate}>
                    <div className="row ">
                      {eventFields
                        .filter(
                          (field) =>
                            (field?.condition?.field === "" ||
                              !field.condition) &&
                            !field?.field?.toLowerCase().includes("sml") &&
                            !field?.field?.toLowerCase().includes("board")
                        )
                        ?.map((e, index) => {
                          return renderFieldInputs(e, index);
                        })}
                    </div>
                    <div className="row  ">
                      {eventFields
                        .filter((field) => {
                          const conditionField =
                            field?.condition?.field?.toLowerCase();
                          const conditionValue =
                            field?.condition?.value?.toLowerCase();
                          return (
                            conditionField &&
                            formData[conditionField]?.toLowerCase() ===
                              conditionValue &&
                            !field?.field?.toLowerCase().includes("sml") &&
                            !field?.field?.toLowerCase().includes("board")
                          );
                        })
                        ?.map((e, index) => {
                          return renderFieldInputs(e, index);
                        })}
                    </div>
                    {edit && msgError && (
                      <Alert severity="error">{msgError}</Alert>
                    )}
                    <Button
                      className={"update w-100 mt-2"}
                      onClick={(e) => handleUpdate(e)}
                      type="submit"
                      disabled={loadingUpdate}
                      style={{
                        color: "white",
                        backgroundColor: loadingUpdate ? "gray" : "#1eb4bc",
                        border: "0",
                      }}
                    >
                      {loadingUpdate ? "..Save" : "Save"}
                    </Button>
                  </Form>
                  {eventFields?.map((e) => {
                    if (e.field.includes("international_board_acceptance")) {
                      let found = data?.find(([key, value]) => {
                        return key === "international_board_acceptance";
                      });
                      if (found == undefined) {
                        return renderField(
                          "international_board_acceptance",
                          "value"
                        );
                      } else {
                        renderField(found[0], found[1]);
                      }
                    }
                    return null;
                  })}
                  {/* {
  eventFields.map((e) => {
    if (e.field.includes("international_board_acceptance")) {
      let found = false;

      return data?.map(([key, value]) => {
        if (key === "international_board_acceptance" && !found) {
          found = true; // Set the flag to true
          return renderField(key, value); 
        }
        if (!found) {
          return renderField("international_board_acceptance", "value");
        }
        return null; 
      });
    }
    return null; 
  })
} */}

                  {data?.map(
                    ([key, value]) =>
                      key == "international_board_acceptance" &&
                      renderField(key, value)
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              <Skeleton></Skeleton>
              <Skeleton></Skeleton>
              <Skeleton></Skeleton>
              <Skeleton></Skeleton>
            </>
          )}
          <div className="row">
            {" "}
            {data &&
              !edit &&
              data?.map(
                ([key, value, object]) =>
                  (object?.condition?.find((i) => i.field === "") ||
                    !object.condition) &&
                  renderField(key, value, object)
              )}
            {data &&
              !edit &&
              data
                .filter(([key, value, object]) => {
                  const conditionField = object?.condition?.map((i) => {
                    return i?.field?.toLowerCase();
                  });
                  const conditionValue = object?.condition?.map((i) => {
                    return i?.value?.toLowerCase();
                  });
                  return (
                    conditionField &&
                    conditionField.some(
                      (field, index) =>
                        dataAll[field]?.toLowerCase() ===
                        conditionValue[index]?.toLowerCase()
                    )
                  );
                })
                .map(([key, value, object]) => renderField(key, value, object))}
            {eventFields?.map((e) => {
              if (e.field.includes("international_board_acceptance")) {
                let found = data?.find(([key, value]) => {
                  return key === "international_board_acceptance";
                });
                if (!found) {
                  return renderField("international_board_acceptance", "value");
                }
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
}
