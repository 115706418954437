import "./Hackathon.css";
import { useEffect, useState } from "react";
import getCookie from "../../utiles/Cookie";
import View from "./View";
import Apply from "./Apply";
import { Skeleton } from "@mui/material";

const Hackathon = () => {
  const user = Boolean(getCookie("user-login-fps"))
    ? JSON.parse(getCookie("user-login-fps"))
    : null;

  const [register, setRegister] = useState();
  const [registerData, setRegisterData] = useState();

  // Function to fetch hackathon data
  const fetchHackathonData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/hackathon`,
        {
          headers: {
            "Content-Type": "application/json",
            token: `Bearer ${user.token}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);
      if (data?.data) {
        setRegisterData(data.data);
        setRegister(true);
      } else {
        setRegister(false);
      }
    } catch (error) {
      console.error("Error fetching hackathon data:", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchHackathonData();
    }
  }, []);

  return (
    <div className="h-100">
      {register == null ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1.2rem" }} />

        </>
      ) : register == true ? (
        <View
          token={user.token}
          data={registerData}
          fetchHackathonData={fetchHackathonData}
        />
      ) : (
        <Apply token={user.token} fetchHackathonData={fetchHackathonData} />
      )}
    </div>
  );
};

export default Hackathon;
