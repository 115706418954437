import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import getCookie from "../../../utiles/Cookie";
import { Alert, Snackbar } from "@mui/material";

export default function ChangePass({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [msg, setMsg] = useState(null);
  const [newPassError, setNewPassError] = useState(""); // New state for password validation message
  const user = Boolean(getCookie("user-login-fps"))
  ? JSON.parse(getCookie("user-login-fps"))
  : null;

  const resetForm = () => {
    setMsg(null);
    setOldPassword("");
    setNewPassword("");
    setNewPassError("");
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
  };

  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Validate the new password (min length 8)
  const validateNewPassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate new password before proceeding
    const newPasswordValidationError = validateNewPassword(newPassword);
    if (newPasswordValidationError) {
      setNewPassError(newPasswordValidationError);
      return; // Stop form submission if validation fails
    }

    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/profile/change-password`,
        {
          currentPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            token: `Bearer ${user?.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      handleClick();
      handleClose();
      resetForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMsg(
        error?.response?.data?.errors
          ? error?.response?.data?.errors[0]?.msg
          : error?.response?.data?.message
      );
     ( !error?.response)  && setMsg('Could you please check your network connection? It seems there may be an issue.');
     
    }
  };

 
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        message="Password changed successfully."
      />
      <Dialog
        open={open}
        onClose={handleCloseAlert}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
            <InputLabel htmlFor="old-password">Old Password</InputLabel>
            <Input
              id="old-password"
              type={showOldPassword ? "text" : "password"}
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
                setMsg(null);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
            <InputLabel htmlFor="new-password">New Password</InputLabel>
            <Input
              id="new-password"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setNewPassError(""); // Clear password error message when typing
                setMsg(null);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          {/* Error message for new password validation */}
          {newPassError && (
            <Alert severity="error" className="mt-3 w-100">
              {newPassError}
            </Alert>
          )}

          {msg && (
            <Alert severity="error" className="mt-3 w-100">
              {msg}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="btn-dialog">
            Cancel
          </Button>
          <Button
            type="submit"
            className={`btn-dialog ${loading && "loading"}`}
            disabled={loading}
          >
            {loading ? "...Update" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
