import React , { useState , useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Alert } from "@mui/material";

const SetPass = () => {
  const navigate = useHistory();
  const [msg, setMsg] = useState();
  const [done, setDone] = useState(false);


    const [newPassword, setNewPass] = useState("");
    const [confirmPass, setconfirmpass] = useState("");
    const [otp, setOtp] = useState(["", "", "", "", ""]);
    const otpInputs = useRef([]);
  
    const handleOtpChange = (index, value) => {
      if (value.match(/^[0-9]?$/)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
  
        // Move focus to next input if a digit was entered
        if (value !== "" && index < 4) {
          otpInputs.current[index + 1].focus();
        }
      }
    };

  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const email = localStorage.getItem("emailpass");
      const combinedOtp = otp.join("");
      if (newPassword !== confirmPass) {
        setMsg("Passwords do not match");
        return;
      }
      else{
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/reset-password`,
            // 67570
            {email , newPassword, otp: combinedOtp},
            { headers: { "Content-Type": "application/json" } }
          );
          console.log(response.data);
          setDone(true);
    
          return response.data;
        } catch (error) {
          if (error.response) {
            setMsg(
              error.response.data.message
                ? error.response.data.message
                : error.response.data.errors[0].msg
            );
          } else if (error.request) {
            console.error("Error request data:", error.request);
          } else {
            console.error("Error message:", error.message);
          }
        }

      }

    };
  return (
    <div className="container-fluid p-0 mt-5">
      {
        done ? 
        <div className="row">
          <div className="col w-100 d-flex flex-column justify-content-center">
            <div className="container form-container bg-white d-flex align-items-center justify-content-center p-0 ">
              <div className="w-100 d-flex flex-column align-items-center mb-5">
                <h1 className="login-heading w-100 d-flex justify-content-start mb-5 fw-bold">
                  Password set successfully
                </h1>
                <button onClick={() => navigate.push("/login")} className="login-button w-100 mt-5 p-2 fw-bold">Login</button>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="row">
        <div className="col w-100 d-flex flex-column justify-content-center">
        <div className="container form-container bg-white d-flex align-items-center justify-content-center p-0 ">
          <form onSubmit={handleSubmit} className="w-100 d-flex flex-column align-items-center mb-5">
            <h1 className="login-heading w-100 d-flex justify-content-start fw-bold">
              Set a password
            </h1>
            <h4 className=" w-100 d-flex justify-content-start mb-5 fw-bold" style={{ color: "green" }}>
                We have sent you a password reset instruction. Please check your email.
                </h4>
            <label htmlFor="email" className="w-100 mb-3 fw-bold">
              Create new password <span className="required">*</span>
              <input
                type="password"
                required
                className="form-control"
                id="pass"
                onChange={(e) => setNewPass(e.target.value)}
                placeholder="Enter your new password"
              />
            </label>
            <label htmlFor="email" className="w-100 my-3 fw-bold">
              Confirm new password <span className="required">*</span>
              <input
                type="password"
                required
                className="form-control"
                id="pass"
                onChange={(e) => setconfirmpass(e.target.value)}
                placeholder="Confirm your new password"
              />
            </label>

            <label htmlFor="email" className="w-100 my-3 fw-bold">
             Enter OTP <span className="required">*</span>
             {/* OTP 5 inputs */}
              </label>

              <div className="d-flex justify-content-between w-100">
      {[0, 1, 2, 3, 4].map((index) => (
        <input
          key={index}
          type="text"
          required
          className="form-control otp_input mx-2"
          value={otp[index]}
          onChange={(e) => handleOtpChange(index, e.target.value)}
          ref={(el) => (otpInputs.current[index] = el)}
          maxLength={1}
        />
      ))}
    </div>
              {msg && (
                <Alert severity="error" className="mt-3 w-100">
                  {msg}
                </Alert>
              )}

            <button
              type="submit"
              className="login-button w-100 mt-5 p-2 fw-bold"
              // onClick={handleSubmit}
            >
              Set password
            </button>
          </form>
        </div>
      </div>
        </div>
      }

    </div>
  );
};

export default SetPass;
