import { Form, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { Alert } from "@mui/material";
export default function Apply({ token, fetchHackathonData }) {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = () => {
      fetch(`${process.env.REACT_APP_API_URL}/hackathon/static`, {
        headers: {
          token: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);
  // ======object Team array
  const [rows, setRows] = useState([
    {
      f_name: "",
      l_name: "",
      m_name: "",
      email: "",
      National_ID: "",
      emailError: false,
      duplicateError: false,
    },
    {
      f_name: "",
      l_name: "",
      m_name: "",
      email: "",
      National_ID: "",
      emailError: false,
      duplicateError: false,
    },
  ]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        f_name: "",
        l_name: "",
        m_name: "",
        email: "",
        National_ID: "",
        emailError: false,
        duplicateError: false,
      },
    ]);
  };
  const emailInputRefs = useRef([]);

  useEffect(() => {
    rows.forEach((t, index) => {
      if (t.emailError || t.duplicateError) {
        emailInputRefs.current[index].focus(); // Focus the input if there's an error
      }
    });
  }, [rows]);
  const handleChangeTeam = (index, field, value) => {
    const updatedRows = rows.map((row, i) =>
      i === index
        ? {
            ...row,
            [field]: value,
            emailError: false, // Reset email error
            duplicateError: false, // Reset duplicate email error
          }
        : row
    );

    setRows(updatedRows);
  };

  const handleClear = (index) => {
    const r = rows.filter((item, i) => i !== index);
    setRows(r);
  };

  const [formData, setFormData] = useState({
    education: "",
    company: "",
    role: "",
    years_of_experience: "",
    skills: "",
    achievements: "",
    team_name: "",
    nationality: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  // handle drop down
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionError, setSelectedOptionError] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const dropMenuRef = useRef(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionSelect = (value, key) => {
    setSelectedOptionIndex(key);
    setSelectedOption(value);
    setIsOpen(false);
    setSelectedOptionError(false)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let hasErrors = false;
    if (!selectedOption) {
   dropMenuRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      setSelectedOptionError(true);
      hasErrors = true;
      return;
    }

    // Check for duplicate emails
    const emails = rows.map((row) => row.email);
    const updatedRows = rows.map((row, index) => {
      const isInvalidEmail = !validateEmail(row.email);
      const isDuplicateEmail = emails.indexOf(row.email) !== index;
      if (isInvalidEmail || isDuplicateEmail) {
        hasErrors = true;
      }
      return {
        ...row,
        emailError: isInvalidEmail,
        duplicateError: isDuplicateEmail,
      };
    });
    setRows(updatedRows);
    if (hasErrors) return;

    // Extract only the required fields for the team array
    const teamData = rows.map(
      ({ f_name, l_name, m_name, email, National_ID }) => ({
        f_name,
        l_name,
        m_name,
        National_ID,
        email,
      })
    );
    fetch(`${process.env.REACT_APP_API_URL}/hackathon`, {
      method: "POST",
      body: JSON.stringify({
        ...formData,
        path: selectedOptionIndex,
        team: teamData,
      }),
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          throw new Error(JSON.stringify(data.errors[0]));
        }
        fetchHackathonData();
      })
      .catch((e) => {
        const errorData = JSON.parse(e.message);
        if (errorData.path && errorData.path.includes("email")) {
          const index = parseInt(errorData.path.match(/\[(\d+)\]/)[1]);

          const updatedRowsWithError = rows.map((row, i) =>
            i === index ? { ...row, emailError: errorData.msg } : row
          );

          setRows(updatedRowsWithError);
        }

        console.error("Error:", errorData.msg);
      });
  };
  return (
    <>
      <h1 className="heading-text text-center mt-5 mb-0">
        Hackathon Registration
      </h1>
      <div className="primary-info row my-3 mx-0 mx-lg-5">
        <p className="primary-info__heading fs-4 fw-bolder">Experiences </p>
        <Form onSubmit={handleSubmit} className="mt-3">
          <div className="row">
            <Form.Group controlId="education" className="col-12 col-lg-4 mb-5">
              <Form.Label className="form-label">
                Education <span className="start-required">*</span>
              </Form.Label>
              <Form.Control
                className="border-0"
                type="text"
                name="education"
                value={formData.education}
                onChange={handleChange}
                placeholder="Enter your Education"
                required
              />
            </Form.Group>

            <Form.Group controlId="company" className="col-12 col-lg-4 mb-5">
              <Form.Label className="form-label">
                Institution/Company <span className="start-required">*</span>
              </Form.Label>
              <Form.Control
                className="border-0"
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                placeholder="Enter your Institution/Company"
                required
              />
            </Form.Group>

            <Form.Group controlId="role" className="col-12 col-lg-4 mb-5">
              <Form.Label className="form-label">
                Current Role <span className="start-required">*</span>
              </Form.Label>
              <Form.Control
                className="border-0"
                type="text"
                name="role"
                value={formData.role}
                onChange={handleChange}
                placeholder="Enter your Current Role"
                required
              />
            </Form.Group>
            <Form.Group
              controlId="years_of_experience"
              className="mb-5 col-12 col-lg-4"
              
            >
              <Form.Label className="form-label">
                Years of experiences <span className="start-required">*</span>
              </Form.Label>
              <Form.Check
                type="radio"
                label="A year or less"
                name="years_of_experience"
                value="A year or less"
                required
                onChange={handleChange}
                checked={formData.years_of_experience === "A year or less"}
              />
              <Form.Check
                type="radio"
                label="2-5 years"
                name="years_of_experience"
                required
                value="2-5 years"
                onChange={handleChange}
                checked={formData.years_of_experience === "2-5 years"}
              />
              <Form.Check
                type="radio"
                required
                label="More than 5"
                name="years_of_experience"
                value="More than 5"
                onChange={handleChange}
                checked={formData.years_of_experience === "More than 5"}
              />
            </Form.Group>
            <Form.Group
              controlId="years_of_experience"
              className="mb-5 col-12 col-lg-4"
            >
              <Form.Label className="form-label">
                Hackathon paths <span className="start-required">*</span>
              </Form.Label>
              <div className="dropdown w-100" ref={dropMenuRef}>
                <div
                  className="dropdown-header d-flex justify-content-between align-items-center"
                  onClick={toggleDropdown}
                >
                  <span>
                    {selectedOption ? selectedOption : "Select one only"}
                  </span>
                  <i className={`arrow ${isOpen ? "open" : ""}`}></i>
                </div>
                {isOpen && (
                  <div className="dropdown-options" >
                    {data &&
                      Object.entries(data).map(([key, value]) => (
                        <label
                          key={key}
                          className="dropdown-option d-flex justify-content-between align-items-center"
                        >
                          {value}

                          <input
                            type="radio"
                            name="options"
                            value={value}
                            checked={selectedOption === value}
                            onChange={(e) => {
                              handleChange(e);
                              handleOptionSelect(value, key);
                            }}
                          />
                        </label>
                      ))}
                  </div>
                )}
                {selectedOptionError && (
                  <Alert severity="error" className="my-2">
                    This field is required
                  </Alert>
                )}
              </div>
            </Form.Group>
          </div>

          <Form.Group controlId="skills" className="mb-5">
            <Form.Label className="form-label">
              Skills/Expertise <span className="start-required">*</span>
            </Form.Label>
            <Form.Control
              className="border-0"
              as="textarea"
              rows={6}
              name="skills"
              value={formData.skills}
              onChange={handleChange}
              placeholder="Enter your Message"
              required
            />
          </Form.Group>

          <Form.Group controlId="achievements" className="mb-5">
            <Form.Label className="form-label">
              Share your achievements and success indicators{" "}
              <span className="start-required">*</span>
            </Form.Label>
            <Form.Control
              className="border-0"
              as="textarea"
              rows={6}
              name="achievements"
              value={formData.achievements}
              onChange={handleChange}
              placeholder="Enter your Message"
              required
            />
          </Form.Group>
          <p className="primary-info__heading fs-4 fw-bolder">Add your team</p>
          <Form.Group controlId="team_name" className="col-12 col-lg-4 mb-5">
            <Form.Label className="form-label">
              Team’s Name <span className="start-required">*</span>
            </Form.Label>
            <Form.Control
              className="border-0"
              name="team_name"
              value={formData.team_name}
              onChange={handleChange}
              placeholder="Enter your team’s Name"
              required
            />
          </Form.Group>
          {rows.map((t, index) => (
            <div key={index}>
              <p className="primary-info__heading fs-4 fw-bolder">
                <h3 className="mt-4">
                  {index == 0
                    ? "1st member"
                    : index == 1
                    ? "2nd member"
                    : "3rd member"}
                </h3>
              </p>
              <div className="row">
                <Form.Group controlId="f_name" className="col-12 col-lg-4 mb-5">
                  <Form.Label className="form-label">
                    First Name <span className="start-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="border-0"
                    type="text"
                    name="f_name"
                    defaultValue={t.f_name}
                    onChange={(e) =>
                      handleChangeTeam(index, "f_name", e.target.value)
                    }
                    placeholder="Enter your First Name"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="m_name" className="col-12 col-lg-4 mb-5">
                  <Form.Label className="form-label">
                    Middle Name <span className="start-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="border-0"
                    type="text"
                    name="m_name"
                    defaultValue={t.m_name}
                    onChange={(e) =>
                      handleChangeTeam(index, "m_name", e.target.value)
                    }
                    placeholder="Enter your Middle Name"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="l_name" className="col-12 col-lg-4 mb-5">
                  <Form.Label className="form-label">
                    Last Name <span className="start-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="border-0"
                    type="text"
                    name="l_name"
                    defaultValue={t.l_name}
                    onChange={(e) =>
                      handleChangeTeam(index, "l_name", e.target.value)
                    }
                    placeholder="Enter your Last Name"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="email" className="col-12 col-lg-4 mb-4">
                  <Form.Label className="form-label">
                    E-mail <span className="start-required">*</span>
                  </Form.Label>
                  <Form.Control
                    ref={(el) => (emailInputRefs.current[index] = el)} // Assign ref to each email input
                    className={`border-0 ${
                      t.emailError || t.duplicateError ? "error-border" : ""
                    }`}
                    name="email"
                    defaultValue={t.email}
                    onChange={(e) =>
                      handleChangeTeam(index, "email", e.target.value)
                    }
                    placeholder="Enter your email"
                    required
                  />
                  {t.emailError ? (
                    <Alert severity="error" className="my-2">
                      Invalid email format.
                    </Alert>
                  ) : t.duplicateError ? (
                    <Alert severity="error" className="my-2">
                      Duplicate email found.
                    </Alert>
                  ) : null}
                </Form.Group>

                <Form.Group
                  controlId="National_ID"
                  className="col-12 col-lg-4 mb-5"
                >
                  <Form.Label className="form-label">
                    National ID <span className="start-required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="border-0"
                    type="text"
                    name="National_ID"
                    defaultValue={t.National_ID}
                    onChange={(e) =>
                      handleChangeTeam(index, "National_ID", e.target.value)
                    }
                    placeholder="Enter your National ID"
                    required
                  />
                </Form.Group>

                {index + 1 === rows.length && rows.length < 3 && (
                  <button
                    type="button"
                    className="border-0 bg-transparent btn-member mb-4 col-12 col-lg-4 mb-5"
                    onClick={handleAddRow}
                  >
                    + Add new member
                  </button>
                )}

                {index + 1 === rows.length && rows.length === 3 && (
                  <button
                    type="button"
                    className="border-0 bg-transparent btn-member mb-4 col-12 col-lg-4 mb-5"
                    onClick={() => handleClear(index)}
                  >
                    - Remove last member
                  </button>
                )}
              </div>
            </div>
          ))}

          <Form.Group controlId="nationality" className="mb-5">
            <Form.Check
              type="checkbox"
              label="I confirm having a Saudi nationality"
              name="nationality"
              checked={formData.nationality}
              onChange={handleChange}
            />
          </Form.Group>
          <p className="primary-info__heading fs-4 fw-bolder">
            Consent and Agreement
          </p>
          <p>
            1- Acceptance of the Privacy Notice <br />
            2- Changes to the Privacy Notice <br />
            3- Legal justification for collecting and processing data <br />
            4- Mechanism for collecting personal data <br />
            5- Disclosure of personal data <br />
            6- Retention of personal data
          </p>
          <div className="w-100 d-flex justify-content-center my-5">
            <Button type="submit">REGISTER</Button>
          </div>
        </Form>
      </div>
    </>
  );
}
