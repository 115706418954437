const API_URL = `${process.env.REACT_APP_API_URL}/user/profile`;

export const GetUser = async (user) => {
  try {
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: `Bearer ${user?.token}`,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    return result.user;
  } catch (error) {
    console.error("Failed to get user profile:", error);
    throw error;
  }
};
